<template>
  <div class="container">

    <div class="row align-items-center justify-content-center content-body">
      <div class="col-auto">

        <div class="row" style="max-width: 600px">
          <div class="col-12 form-group card-step px-sm-5">
            <div class="px-5 text-center">
              <div class="title-stepper mb-5">Sign up for Alpha Access.</div>
            </div>

            <div class="row align-items-start justify-content-center">
              <div class="col form-group input-group-lg">
                <vue-tel-input
                    v-model="form.mobile"
                    placeholder="Telephone*"
                    type="text"
                    class="form-control"
                    @validate="setMobileNo"
                    mode="international"
                    :validCharactersOnly="true">
                </vue-tel-input>
                <error-message class="mt-1" :msg="errorMessage.mobile"></error-message>
              </div>

              <div class="col-auto form-group input-group-lg">
                <button class="btn btn-sendotp btn-lg w-100" @click="getOTP" :disabled="isSendOTP">SEND OTP</button>
              </div>
            </div>

            <div id="recaptcha"></div>

            <div class="row align-items-start">
              <div class="col-auto form-group input-group-lg padding-ref">
                <span class="text-ref" v-if="form.ref">Ref : {{ form.ref }}</span>
              </div>
              <div class="col form-group input-group-lg padding-resend" v-if="isSendOTP">
            <span class="text-resend">Didn’t Receive OTP?
              <font class="text-otp" @click="resendOTP">Resend OTP</font></span>
              </div>
            </div>

            <transition name="slide-fade">
              <div class="row align-items-start justify-content-center mb-3" v-if="isSendOTP">
                <div class="col-12 form-group input-group-lg">
                  <input type="text" class="form-control" placeholder="OTP" v-model="form.otp"/>
                  <error-message class="mt-1" :msg="errorMessage.otp"></error-message>
                </div>
              </div>
            </transition>

            <div class="row align-items-center justify-content-center mb-5">
              <div class="col-12 d-grid gap-2 form-group text-center input-group-lg">
                <button class="btn btn-start btn-lg w-100" @click="verifyOTP" :disabled="!isSendOTP">
                  VERIFY OTP
                </button>
              </div>
            </div>


          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import validate from "validate.js";
import ErrorMessage from "../components/ErrorMessage";
import store from "@/store";
import Swal from "sweetalert2";
import Vue from "vue";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import {GET_OTP, VERIFY_OTP} from "@/store/actions.type";
import {RecaptchaVerifier} from "firebase/auth";
import {auth} from "@/utils/firebase";

Vue.use(VueTelInput, {
  inputOptions: {placeholder: "Telephone*"},
  defaultCountry: "TH"
});

export default {
  name: "Register",
  components: {ErrorMessage},
  data() {
    return {
      form: {
        name: "Test register",
        email: "test@siamsquared.com",
        mobile: "",
        ref: this.$route.query.ref || "",
        otp: ""
      },
      errorMessage: {},
      phoneObject: {},
      isSendOTP: false
    };
  },
  methods: {
    setMobileNo(event) {
      this.phoneObject = event;
    },
    async getOTP() {
      let errorMessage = this.validateForm('getOTP', this.form);
      if (errorMessage) {
        return (this.errorMessage = errorMessage);
      } else {
        this.errorMessage = {};
      }

      this.isSendOTP = true;
      let {number} = this.phoneObject;
      window.recaptchaVerifier = new RecaptchaVerifier('recaptcha', {
        'size': 'invisible',
        'callback': async (response) => {
          console.log(response);
        }
      }, auth);

      let otpResult = await store.dispatch(GET_OTP, number);
      console.log(otpResult);
    },
    async resendOTP() {
      let errorMessage = this.validateForm('resendOTP', this.form);
      if (errorMessage) {
        return (this.errorMessage = errorMessage);
      } else {
        this.errorMessage = {};
      }
      if (this.isLoading) return;
      this.isLoading = true;

      await window.recaptchaVerifier.render().then(async (widgetId) => {
        grecaptcha.reset(widgetId);
        let {number} = this.phoneObject;
        let otpResult = await store.dispatch(GET_OTP, number);
        console.log('resend ', otpResult);
      });

      this.isLoading = false;
    },
    async verifyOTP() {
      let errorMessage = this.validateForm('verifyOTP', this.form);
      if (errorMessage) {
        return (this.errorMessage = errorMessage);
      } else {
        this.errorMessage = {};
      }

      if (this.isLoading) return;
      this.isLoading = true;
      let verifyOTPResult = await store.dispatch(VERIFY_OTP, this.form.otp);
      this.isLoading = false;

      if (verifyOTPResult.user) {
        return Swal.fire({
          html: `<div>Register success</div>`,
          icon: "success",
          customClass: {
            confirmButton: "btn-primary",
          },
          allowOutsideClick: false,
          confirmButtonText: "OK",
        }).then(() => {
          this.$router.push('/ranking');
        });
      } else {
        this.errorMessage = {};
        return this.errorMessage.otp = verifyOTPResult.code || 'Please try again later.';
      }
    },
    validateForm(state, data) {
      let errMessageList = null;
      let validateRule = null;

      validate.validators.checkMobileNo = function () {
        return this.phoneObject.valid ? null : '^ Invalid mobile number'
      }.bind(this);

      if (state === 'getOTP') {
        validateRule = {
          mobile: {
            presence: {
              allowEmpty: false,
              message: "^Please enter mobile no",
            },
            checkMobileNo: {
              message: "^Invalid mobile number"
            }
          }
        }
      } else if (state === "verifyOTP") {
        validateRule = {
          mobile: {
            presence: {
              allowEmpty: false,
              message: "^Please enter mobile no",
            },
            checkMobileNo: {
              message: "^Invalid mobile number"
            }
          },
          otp: {
            presence: {
              allowEmpty: false,
              message: "^Please enter code",
            }
          }
        }
      } else if (state === "resendOTP") {
        validateRule = {
          mobile: {
            presence: {
              allowEmpty: false,
              message: "^Please enter mobile no",
            },
            checkMobileNo: {
              message: "^Invalid mobile number"
            }
          }
        }
      }

      errMessageList = validate(
          {
            mobile: data.mobile,
            otp: data.otp,
          },
          validateRule
      );

      if (errMessageList) {
        const error = {};
        for (const [key, value] of Object.entries(errMessageList)) {
          error[key] = value[0];
        }
        return error;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.btn-start {
  border-radius: 20px;
}

.vti__country-code {
  margin-right: 8px;
}
</style>
